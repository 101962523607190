import React, { useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import QRCode from 'react-qr-code';
import QRCodeLink from 'qrcode';
import backgroundImage from '../../../assets/logo_takao.png';
import backgroundImageCar from '../../../assets/takaoSubaru.jpg';
import imageQrCodeApp from '../../../assets/box-info-app.png';
import Grid from '@mui/system/Unstable_Grid';
import styled from '@mui/system/styled';
import imprimirIcone from '../../../assets/btn-imprimir.png';
import Box from '@mui/system/Box';


function QRCodeBalconista() {
  const location = useLocation();
  const { qrCodeValue, nomeBalconista, nomeAutoPeca } = location.state;

  if (!qrCodeValue || !nomeBalconista || !nomeAutoPeca) {
    return <div>Error: Form data not found</div>;
  }

  var qrCodeForDowload = '';

  QRCodeLink.toDataURL(qrCodeValue, {
    width: 600,
    margin: 3,
  }, function (err, url) {
    qrCodeForDowload = url;
  });

  const Title = styled('div')(({ theme }) => ({
    fontSize: '16px',
    color: 'red',
    padding: theme.spacing(0),
    textAlign: 'left',
    fontFamily: 'Trebuchet MS'

  }));

  const Subtitle = styled('div')(({ theme }) => ({
    fontSize: '26px',
    color: 'black',
    padding: theme.spacing(0),
    textAlign: 'left',
    fontFamily: 'Trebuchet MS'

  }));
  const CommonText = styled('div')(({ theme }) => ({
    fontSize: '16px',
    color: 'black',
    textAlign: 'left',
    fontFamily: 'Trebuchet MS'
  }));
  const handleImprimirClick = () => {
    window.print();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundImage: `url(${backgroundImageCar})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: 'white',
        '@media print': {
          height: '80vh',
        }

      }}
    >
      <Box
        sx={{
          border: '4px solid #d30000',
          margin: '20rem',
          display: 'flex',
          backgroundColor: 'white',
          borderRadius: '15px',
          '@media print and (orientation: portrait)': {
            marginTop: '10rem', marginLeft: '10rem', marginBottom: '0', marginRight: '10rem'
          },
          '@media print and (orientation: landscape)': {
            marginTop: '10rem', marginLeft: '9rem', marginBottom: '0', marginRight: '9rem'
          },

        }}
      >
        <Grid container
          alignContent={'center'}
        paddingBottom={3}
        >
          <Grid xs={12} container justifyContent="flex-end" sx={{ '@media print': { display: 'none' } }}>
            <button
              onClick={handleImprimirClick}
              style={{
                background: 'transparent',
                border: 'none',
                cursor: 'pointer',
                paddingRight: '15px'
              }}
            >
              <img
                src={imprimirIcone}
                alt="Imprimir"
                style={{
                  width: '60px', height: '60px',
                }}
              />
              <CommonText>Imprimir</CommonText>
            </button>
          </Grid>
          <Grid xs={12}
            paddingLeft={8}>
            <Title><strong>Olá, sou...</strong></Title>
            <Subtitle><strong>{nomeBalconista}, </strong> da {nomeAutoPeca}</Subtitle>
            <Title><strong>Com o QR-Code abaixo, você pode me </strong></Title>
            <Title><strong>indicar como seu vendedor no APP.</strong></Title>
          </Grid>
          <Grid xs={12} md={6}
            paddingLeft={8}
            paddingTop={3}
          >
            <QRCode value={qrCodeValue} style={{ border: 'none', backgroundColor: 'white', height: '300px', }} />
          </Grid>
          <Grid
            display={'block'}
            gridColumn={4}
            paddingTop={3}
          >
            <img
              src={imageQrCodeApp}
              alt=""
              style={{ height: '300px', }} />
          </Grid>
        </Grid>
      </Box>

    </Box>
  );
}

export default QRCodeBalconista;
