import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import InputMask from 'react-text-mask';
import TakaoLogo from '../../../assets/logo_takao_preto.png';
import backgroundImageCar from '../../../assets/takaoSubaruHome.jpg'
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import React, { useState } from 'react';
import ReactSelect from 'react-select'

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © - '}
      {new Date().getFullYear()}
      {' '}
      <Link color="inherit" href="https://site.takao.com.br/politica-de-coleta-e-uso-de-dados-pessoais/">
        Takao - Política de Privacidade
      </Link>{' '}
      {'.'}
    </Typography>
  );
}

const estados = [
  { sigla: 'AC', nome: 'Acre' },
  { sigla: 'AL', nome: 'Alagoas' },
  { sigla: 'AP', nome: 'Amapá' },
  { sigla: 'AM', nome: 'Amazonas' },
  { sigla: 'BA', nome: 'Bahia' },
  { sigla: 'CE', nome: 'Ceará' },
  { sigla: 'DF', nome: 'Distrito Federal' },
  { sigla: 'ES', nome: 'Espírito Santo' },
  { sigla: 'GO', nome: 'Goiás' },
  { sigla: 'MA', nome: 'Maranhão' },
  { sigla: 'MT', nome: 'Mato Grosso' },
  { sigla: 'MS', nome: 'Mato Grosso do Sul' },
  { sigla: 'MG', nome: 'Minas Gerais' },
  { sigla: 'PA', nome: 'Pará' },
  { sigla: 'PB', nome: 'Paraíba' },
  { sigla: 'PR', nome: 'Paraná' },
  { sigla: 'PE', nome: 'Pernambuco' },
  { sigla: 'PI', nome: 'Piauí' },
  { sigla: 'RJ', nome: 'Rio de Janeiro' },
  { sigla: 'RN', nome: 'Rio Grande do Norte' },
  { sigla: 'RS', nome: 'Rio Grande do Sul' },
  { sigla: 'RO', nome: 'Rondônia' },
  { sigla: 'RR', nome: 'Roraima' },
  { sigla: 'SC', nome: 'Santa Catarina' },
  { sigla: 'SP', nome: 'São Paulo' },
  { sigla: 'SE', nome: 'Sergipe' },
  { sigla: 'TO', nome: 'Tocantins' }
];

function TextMaskCustomCPF(props) {
  const { inputRef, ...other } = props;

  const cpfMask = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];

  return (
    <InputMask
      {...other}
      mask={cpfMask}
      guide={false}
      placeholderChar={'\u2000'}
      showMask
    />
  );
};

function TextMaskCustomCNPJ(props) {
  const { inputRef, ...other } = props;

  const cpjMask = [
    /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/',
    /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/
  ];

  return (
    <InputMask
      {...other}
      mask={cpjMask}
      guide={false}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}
const defaultTheme = createTheme();

function LandingPageForm() {

  const [nome, setNome] = useState('');
  const [erro, setErro] = useState('');
  const [uf, setUf] = React.useState('');
  const [cidades, setCidades] = useState([]);

  const handleChangeUF = async (event) => {
    let apiUrl = process.env.REACT_APP_API_URL;

    setUf(event.target.value);
    var uf = event.target.value;
    try {
      const response = await fetch(`${apiUrl}/PainelApp/GetCidadePorEstado`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(uf),
      });
      if (!response.ok) {
        throw new Error('Erro ao enviar dados');
      }

      const responseData = await response.json();

      if (responseData.success) {
        const cidadesFormatadas = responseData.result.map(cidade => ({
          value: cidade,
          label: cidade
        }));
        setCidades(cidadesFormatadas);
      } else {
        Swal.fire({
          title: 'Erro interno no servidor:' + responseData.return,
          confirmButtonColor: 'red',
        });
      }
    } catch (error) {
      Swal.fire({
        title: 'Erro ao buscas Cidades' + error,
        confirmButtonColor: 'red',
      });
    }
  };
  const handleChangeNome = (event) => {
    const valor = event.target.value;
    setNome(valor);
    if (!valor.trim().includes(' ')) {
      setErro('Por favor, insira o nome e sobrenome!');
    } else {
      setErro('');
    }
  };

  const navigate = useNavigate();

  const navigateToContacts = (contactData) => {
    navigate('/balconista', { state: contactData });
  };

  const handleSubmit = async (event) => {
    let apiUrl = process.env.REACT_APP_API_URL;

    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const formData = {
      nomeBalconista: data.get('Nome'),
      cpfBalconista: data.get('cpf'),
      nomeAutoPeca: data.get('autopeca'),
      cnpjAutopeca: data.get('cnpj'),
      emailPromotor: data.get('email'),
      estado: data.get('uf'),
      cidade: data.get('cidade')

    };
    try {
      const response = await fetch(`${apiUrl}/Formulario/GerarCadastroBalconista`, {
        method: 'POST',
        body: JSON.stringify({
          nomeBalconista: formData.nomeBalconista,
          cpfBalconista: formData.cpfBalconista,
          nomeAutoPeca: formData.nomeAutoPeca,
          cnpjAutoPeca: formData.cnpjAutopeca,
          emailPromotor: formData.emailPromotor,
          estado: formData.estado,
          cidade: formData.cidade,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Erro ao enviar dados');
      }

      const responseData = await response.json();

      if (!responseData.success) {
        Swal.fire({
          title: 'Erro interno no servidor:' + responseData.return,
          confirmButtonColor: 'red',
        });
      }
      const qrCodeValue = responseData.result;
      navigateToContacts({
        qrCodeValue,
        nomeBalconista: formData.nomeBalconista,
        nomeAutoPeca: formData.nomeAutoPeca,
      });

    } catch (error) {
      Swal.fire({
        title: 'Preencha os dados do cadastro!',
        confirmButtonColor: 'red',
      });
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" >
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${backgroundImageCar})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: 'white',
            backgroundSize: 'contain',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <img src={TakaoLogo} alt="Logo" style={{ marginBottom: '20px', width: '200px', height: 'auto' }} />

            <Typography component="h1" variant="h5">
              Cadastre-se, indique o APP e os produtos da Takao e concorra a brindes!
            </Typography>

            <div style={{ marginTop: '20px' }}>
              <Typography variant="body1" paragraph style={{ textAlign: 'left' }}>
                Instruções:
              </Typography>
              <Typography variant="body1" paragraph style={{ textAlign: 'left', marginBottom: '0px' }}>
                ( 1 ) Gere seu QR-Code uma única vez
              </Typography>

              <Typography variant="body1" paragraph style={{ textAlign: 'left', marginBottom: '0px' }}>
                ( 2 ) Indique o APP da Takao para os seus clientes
              </Typography>

              <Typography variant="body1" paragraph style={{ textAlign: 'left', marginBottom: '16px' }}>
                ( 3 ) Use o seu QR-Code na hora do cadastro dos clientes
              </Typography>
            </div>

            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="Nome"
                label="Nome do Balconista"
                name="Nome"
                autoComplete="Nome"
                autoFocus
                value={nome}
                onChange={handleChangeNome}
                error={!!erro}
                helperText={erro}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="cpf"
                label="CPF do Balconista"
                name="cpf"
                autoComplete="cpf"
                autoFocus
                InputProps={{
                  inputComponent: TextMaskCustomCPF,
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="E-mail do Balconista"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="autopeca"
                label="Nome da Autopeça"
                name="autopeca"
                autoComplete="autopeca"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="cnpj"
                label="CNPJ da Autopeça"
                name="cnpj"
                autoComplete="cnpj"
                autoFocus
                InputProps={{
                  inputComponent: TextMaskCustomCNPJ,
                }}
              />
              <Select
                value={uf}
                onChange={handleChangeUF}
                required
                displayEmpty
                fullWidth
                id="uf"
                name="uf"
                autoComplete="uf"
                autoFocus
              >
                <MenuItem value="" disabled>
                  Estado da Autopeça*
                </MenuItem>
                {estados.map((estado) => (
                  <MenuItem key={estado.sigla} value={estado.sigla}>
                    {estado.sigla}
                  </MenuItem>
                ))}
              </Select>
              <div style={{ marginTop: '16px' }}>
                <ReactSelect
                  required
                  displayEmpty
                  fullWidth
                  autoFocus
                  id="cidade"
                  name="cidade"
                  placeholder="Cidade da Autopeça"
                  options={cidades}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      paddingTop: '15px',
                    }),
                  }} />
              </div>

              <Button
                color='error'
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Cadastre-se e gere seu qr-code
              </Button>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default LandingPageForm;
