import { BrowserRouter, Route, Routes } from "react-router-dom";
import QRCodeBalconista from './app/modules/qrCodeGenerate/QRCodeBalconista';
import LandingPageForm from "./app/modules/ladingPageForm/ladingPageForm";


function AppRoutes() {
    return (
        <BrowserRouter>
            <Routes>
            <Route path="/" element={<LandingPageForm/>}></Route>
            <Route path="/balconista" element={<QRCodeBalconista/>}></Route>
            </Routes>
        </BrowserRouter>
    );
}
export default AppRoutes;